import HttpService from "./HttpService";

export default class SettingService extends HttpService {
    endPoint = "/api/v1/core/settings"

    getDefault() {
        return this.getJsonRequest().get(this.endPoint + "/default");
    }

    getProfile() {
        return this.getJsonRequest().get(this.endPoint + "/profile");
    }

    getAccount() {
        return this.getJsonRequest().get(this.endPoint + "/account");
    }

    getProfileAndDefault() {
        return this.getAuthTokenRequest().get(this.endPoint + "/profileAndDefault");
    }


    getAccountAndDefault() {
        return this.getAuthTokenRequest().get(this.endPoint + "/accountAndDefault");
    }
}

