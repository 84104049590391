import React, {Component} from 'react';
import {classNames} from 'primereact/utils';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {Toolbar} from 'primereact/toolbar';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {I18n, Translate} from "react-redux-i18n";
import {ContextMenu} from "primereact/contextmenu";
import {Dropdown} from "primereact/dropdown";
import {Order} from "../helpers/order";
import {connect} from "react-redux";
import CategoryService from "../services/CategoryService";
import {Checkbox} from "primereact/checkbox";
import {Chips} from "primereact/chips";
import {string_to_slug} from "../helpers/utils";
import SubCategoryService from "../services/SubCategoryService";

class SubCategory extends Component {
    emptyElement = {
        id: null,
        categoryId: null,
        maxImages: 0,
        minImages: 0,
        nameFr: '',
        nameEn: '',
        statusRequired: true,
        priceRequired: true,
        elements: [],
    };

    emptyElementDescription = {
        icon: '',
        required: false,
        label: '',
        unit: '',
        type: '',
        code: '',
        values: [],
    };

    descriptionTypes = [
        {name: I18n.t('INTEGER'), value: 'INTEGER'},
        {name: I18n.t('REAL'), value: 'REAL'},
        {name: I18n.t('STRING'), value: 'STRING'},
        {name: I18n.t('TEXT'), value: 'TEXT'},
        {name: I18n.t('LIST_STRING'), value: 'LIST_STRING'},
        {name: I18n.t('LIST_INTEGER'), value: 'LIST_INTEGER'},
        {name: I18n.t('LIST_REAL'), value: 'LIST_REAL'},
        {name: I18n.t('DATE'), value: 'DATE'},
        {name: I18n.t('INTEGER_INTERVAL'), value: 'INTEGER_INTERVAL'},
        {name: I18n.t('REAL_INTERVAL'), value: 'REAL_INTERVAL'},
        {name: I18n.t('DATE_INTERVAL'), value: 'DATE_INTERVAL'},
    ];

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            first: 0,
            pageSize: 5,
            totalRecords: 0,
            elements: [],
            elementDialog: false,
            elementDescriptionDialog: false,
            deleteElementDialog: false,
            deleteElementDescriptionDialog: false,
            deleteElementsDialog: false,
            deleteElementsDescriptionDialog: false,
            element: this.emptyElement,
            elementDescription: this.emptyElementDescription,
            selectedElement: null,
            selectedElements: [],
            categories: [],
            menuModel: [],
            sortFilterValues: [],
            submitted: false,
            submittedDescription: false,
            submittedElement: false,
            loadingDialog: false,
            loadingDescriptionDialog: false,
            searchFilterValue: '',
            sortFilterValue: null
        };

        this.elementService = new SubCategoryService();
        this.categoryService = new CategoryService();
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.leftDescriptionToolbarTemplate = this.leftDescriptionToolbarTemplate.bind(this);
        this.openNew = this.openNew.bind(this);
        this.onPage = this.onPage.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveElement = this.saveElement.bind(this);
        this.saveDescriptionElement = this.saveDescriptionElement.bind(this);
        this.editElement = this.editElement.bind(this);
        this.editElementDescription = this.editElementDescription.bind(this);
        this.confirmDeleteElement = this.confirmDeleteElement.bind(this);
        this.deleteElement = this.deleteElement.bind(this);
        this.deleteElementDescription = this.deleteElementDescription.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.confirmDeleteDescriptionSelected = this.confirmDeleteDescriptionSelected.bind(this);
        this.deleteSelectedElements = this.deleteSelectedElements.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputDescriptionChange = this.onInputDescriptionChange.bind(this);
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
        this.hideDeleteElementDialog = this.hideDeleteElementDialog.bind(this);
        this.hideDeleteElementDescriptionDialog = this.hideDeleteElementDescriptionDialog.bind(this);
        this.hideDeleteElementsDialog = this.hideDeleteElementsDialog.bind(this);
        this.openNewDescription = this.openNewDescription.bind(this);
        this.hideDescriptionDialog = this.hideDescriptionDialog.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.onRowReorder = this.onRowReorder.bind(this);
        this.statusRequiredTemplate = this.statusRequiredTemplate.bind(this);
        this.priceRequiredTemplate = this.priceRequiredTemplate.bind(this);
    }


    componentDidMount() {
        this.createOptions();
        this.loadCategories();
        this.setState({loading: true});
        this.elementService.getPage(0, this.state.pageSize, this.state.sortFilterValue?.field, this.state.sortFilterValue?.order).then(
            response => {
                this.setState({
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            }
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locale !== this.props.locale) {
            this.createOptions();
        }
    }

    loadCategories() {
        this.categoryService.getAll().then(
            response => {
                let content = response.data.content ? response.data.content : [];
                let categories = []
                for (let i = 0; i < content.length; i++) {
                    let category = response.data.content[i];
                    categories.push({name: category.nameEn, value: category.id})
                }
                this.setState({categories})
            }
        );
    }

    createOptions() {
        const menuModel = [
            {label: I18n.t('EDIT_DATA'), icon: 'pi pi-fw pi-pencil', command: () => this.editElement(this.state.selectedElement)},
            {label: I18n.t('DELETE_DATA'), icon: 'pi pi-fw pi-trash', command: () => this.confirmDeleteElement(this.state.selectedElement)}
        ];

        const sortFilterValues = [
            {name: I18n.t('SUBCATEGORY_NAME_FR') + ' ' + I18n.t('ASCENDING'), field: 'subcategory.nameFr', order: Order.ASC},
            {name: I18n.t('SUBCATEGORY_NAME_FR') + ' ' + I18n.t('DESCENDING'), field: 'subcategory.nameFr', order: Order.DESC}
        ];
        this.setState(({
            menuModel,
            sortFilterValues
        }));
    }

    onPage(event) {
        this.setState({loading: true});
        const {page, first, rows} = event;
        let searchFilterValue = [{field: 'name', value: this.state.searchFilterValue}];
        this.elementService.getPage(page, rows, this.state.sortFilterValue?.field, this.state.sortFilterValue?.order, searchFilterValue).then(
            response => {
                this.setState({
                    first,
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            }
        );
    }

    openNew() {
        this.setState({
            element: this.emptyElement,
            submitted: false,
            elementDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            elementDialog: false,
            element: this.emptyElement
        });
    }

    openNewDescription() {
        this.setState({
            elementDescription: this.emptyElementDescription,
            submittedDescription: false,
            elementDescriptionDialog: true
        });
    }

    hideDescriptionDialog() {
        this.setState({
            submittedDescription: false,
            elementDescriptionDialog: false,
            elementDescription: this.emptyElementDescription
        });
    }

    hideDeleteElementDialog() {
        this.setState({deleteElementDialog: false});
    }

    hideDeleteElementDescriptionDialog() {
        this.setState({deleteElementDescriptionDialog: false});
    }

    hideDeleteElementsDialog() {
        this.setState({deleteElementsDialog: false});
    }

    saveElement() {
        let state = {submitted: true};
        if (!!this.state.element.nameFr.trim() && !!this.state.element.nameEn.trim() && this.state.element.categoryId != null) {
            let elements = [...this.state.elements];
            this.setState({
                loadingDialog: true,
            });
            if (this.state.element.id) { //may be update
                this.elementService.edit(this.state.element.id, this.state.element)
                    .then((response) => {
                        const index = this.findIndexById(this.state.element.id);
                        elements[index] = response.data.content;
                        state = {
                            ...state,
                            elements,
                            elementDialog: false,
                            element: this.emptyElement,
                            elementDescription: this.emptyElementDescription,
                            submitted: false,
                            loadingDialog: false
                        };
                        this.setState(state);
                        this.toast.show({severity: 'success', summary: I18n.t('UPDATE'), detail: I18n.t('UPDATE_SUCCESSFUL'), life: 6000});
                    })
                    .catch(error => {
                        this.setState({
                            loadingDialog: false,
                        });
                        this.toast.show({severity: 'error', summary: I18n.t('UPDATE'), detail: I18n.t(error.response.data.code), life: 6000});
                    });
            } else { //may be creation
                this.elementService.add(this.state.element)
                    .then((response) => {
                        elements.unshift(response.data.content);
                        state = {
                            ...state,
                            elements,
                            elementDialog: false,
                            element: this.emptyElement,
                            elementDescription: this.emptyElementDescription,
                            submitted: false,
                            loadingDialog: false,
                            totalRecords: this.state.totalRecords + 1,
                        };
                        this.setState(state);
                        this.toast.show({severity: 'success', summary: I18n.t('ADD'), detail: I18n.t('ADD_SUCCESSFUL'), life: 6000});
                    })
                    .catch(error => {
                        this.setState({
                            loadingDialog: false,
                        });
                        this.toast.show({severity: 'error', summary: I18n.t('ADD'), detail: I18n.t(error.response.data.code), life: 6000});
                    });
            }
        }
        this.setState(state);
    }

    saveDescriptionElement() {
        let state = {submittedDescription: true};
        if (!!this.state.elementDescription.label && !!this.state.elementDescription.type) {
            let element = {...this.state.element};
            this.setState({
                loadingDescriptionDialog: true,
            });
            if (this.state.elementDescription.code) { //may be update
                const index = this.findIndexDescriptionByCode(this.state.elementDescription.code);
                element.elements[index] = this.state.elementDescription;
                state = {
                    ...state,
                    element,
                    elementDescriptionDialog: false,
                    elementDescription: this.emptyElementDescription,
                    submittedDescription: false,
                    loadingDescriptionDialog: false,
                };
                this.setState(state);
                this.toast.show({severity: 'success', summary: I18n.t('UPDATE'), detail: I18n.t('UPDATE_SUCCESSFUL'), life: 6000});
            } else { //may be creation
                this.setState({
                    loadingDescriptionDialog: true,
                })
                let elementDescription = {...this.state.elementDescription};
                elementDescription.code = string_to_slug(elementDescription.label, '-', true)
                element.elements.unshift(elementDescription)
                state = {
                    ...state,
                    element,
                    elementDescriptionDialog: false,
                    elementDescription: this.emptyElementDescription,
                    submittedDescription: false,
                    loadingDescriptionDialog: false,
                };
                this.setState(state);
                this.toast.show({severity: 'success', summary: I18n.t('ADD'), detail: I18n.t('ADD_SUCCESSFUL'), life: 6000});
            }
        }
        this.setState(state);
    }

    editElement(element) {
        this.setState({
            element: {...element},
            elementDialog: true
        });
    }

    editElementDescription(element) {

        this.setState({
            elementDescription: {...element},
            elementDescriptionDialog: true
        });
    }

    confirmDeleteElement(element) {
        this.setState({
            element,
            deleteElementDialog: true
        });
    }

    deleteElement() {
        this.setState({
            loadingDialog: true,
        });
        this.elementService.delete(this.state.element.id)
            .then(() => {
                let elements = this.state.elements.filter(val => val.id !== this.state.element.id);
                let selectedElements = this.state.selectedElements.filter(val => val.id !== this.state.element.id);
                this.setState({
                    elements,
                    deleteElementDialog: false,
                    element: this.emptyElement,
                    totalRecords: this.state.totalRecords - 1,
                    loadingDialog: false,
                    selectedElements
                });
                this.toast.show({severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    deleteElementDialog: false,
                    element: this.emptyElement,
                    loadingDialog: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('DELETION'), detail: I18n.t(error.response.data.code), life: 6000});
            });
    }

    deleteElementDescription() {
        this.setState({
            loadingDescriptionDialog: true,
        });
        let element = {...this.state.element};
        let elements = element.elements.filter(val => val.code !== this.state.elementDescription.code);
        element.elements = elements
        this.setState({
            element,
            deleteElementDescriptionDialog: false,
            elementDescription: this.emptyElementDescription,
            loadingDescriptionDialog: false
        });
        this.toast.show({severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000});
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.elements.length; i++) {
            if (this.state.elements[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    findIndexDescriptionByCode(code) {
        let index = -1;
        for (let i = 0; i < this.state.element.elements.length; i++) {
            if (this.state.element.elements[i].code === code) {
                index = i;
                break;
            }
        }
        return index;
    }

    confirmDeleteSelected() {
        this.setState({deleteElementsDialog: true});
    }

    confirmDeleteDescriptionSelected(elementDescription) {
        this.setState({elementDescription, deleteElementDescriptionDialog: true});
    }

    deleteSelectedElements() {
        this.setState({
            loadingDialog: true,
        });
        let dataIds = this.state.selectedElements.map((el) => el.id);
        this.elementService.deleteMany(dataIds)
            .then(() => {
                let elements = this.state.elements.filter(val => !this.state.selectedElements.includes(val));
                this.setState({
                    totalRecords: this.state.totalRecords - this.state.selectedElements.length,
                    elements,
                    deleteElementsDialog: false,
                    selectedElements: [],
                    loadingDialog: false,
                });
                this.toast.show({severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    deleteElementsDialog: false,
                    loadingDialog: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('DELETION'), detail: I18n.t(error.response.data.code), life: 6000});
            });
    }

    onInputChange(e, name) {
        let val;
        if (name === 'statusRequired' || name === 'priceRequired') {
            val = e.checked;
        } else {
            val = (e.target && e.target.value) || '';
        }
        let element = {...this.state.element};
        element[`${name}`] = val;
        this.setState({element});
    }

    onInputDescriptionChange(e, name) {
        let val;
        if (name === 'required') {
            val = e.checked
        } else if (name === 'values') {
            val = e.value
        } else {
            val = (e.target && e.target.value) || '';
        }
        let elementDescription = {...this.state.elementDescription};
        elementDescription[`${name}`] = val;
        this.setState({elementDescription});
    }

    onGlobalFilterChange(e, filterName) {
        const value = (e.target && e.target.value) || '';
        let searchFilterValue = this.state.searchFilterValue;
        let sortFilterValue = this.state.sortFilterValue;
        if (filterName === 'searchFilter') {
            searchFilterValue = value;
            this.setState({searchFilterValue, loading: true, first: 0});
        }

        if (filterName === 'sortFilter') {
            sortFilterValue = e.value
            this.setState({sortFilterValue, loading: true, first: 0});
        }

        let searchValue = [{field: 'name', value: searchFilterValue}];
        this.elementService.getPage(this.state.first, this.state.pageSize, sortFilterValue?.field, sortFilterValue?.order, searchValue)
            .then(response => {
                this.setState({
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('SEARCH'), detail: I18n.t('SEARCH_NOT_IMPLEMENTED'), life: 6000});
            });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label={I18n.t('ADD_DATA')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew}/>
                <Button label={I18n.t('DELETE_DATA')} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedElements.length}/>
            </React.Fragment>
        )
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => this.editElementDescription(rowData)}/>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => this.confirmDeleteDescriptionSelected(rowData)}/>
            </React.Fragment>
        );
    }

    leftDescriptionToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label={I18n.t('ADD_DATA')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNewDescription}/>
            </React.Fragment>
        )
    }

    onRowReorder(e) {
        let element = {...this.state.element};
        element.elements = e.value;
        this.setState({element});
    }

    statusRequiredTemplate(rowData) {
        if (rowData.statusRequired === true) {
            return 'OUI'
        }
        return 'NON'
    }

    priceRequiredTemplate(rowData) {
        if (rowData.priceRequired === true) {
            return 'OUI'
        }
        return 'NON'
    }

    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-m-0">{I18n.t('SECTIONS')}</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search"/>
                    <InputText style={{width: '300px'}} type="search" value={this.state.searchFilterValue} onInput={(e) => this.onGlobalFilterChange(e, 'searchFilter')} placeholder={I18n.t('SEARCH_DATA')}/>
                 <Dropdown style={{width: '200px', marginLeft: '7px'}} value={this.state.sortFilterValue} onChange={(e) => this.onGlobalFilterChange(e, 'sortFilter')}
                           options={this.state.sortFilterValues} optionLabel="name" placeholder={I18n.t('SELECT_ORDER')}/>
                </span>
            </div>
        );
        const elementDialogFooter = (
            <React.Fragment>
                <Button loading={this.state.loadingDialog} label={I18n.t('CANCEL')} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog}/>
                <Button loading={this.state.loadingDialog} label={I18n.t('SAVE')} icon="pi pi-check" className="p-button-text" onClick={this.saveElement}/>
            </React.Fragment>
        );

        const elementDialogDescriptionFooter = (
            <React.Fragment>
                <Button loading={this.state.loadingDescriptionDialog} label={I18n.t('CANCEL')} icon="pi pi-times" className="p-button-text" onClick={this.hideDescriptionDialog}/>
                <Button loading={this.state.loadingDescriptionDialog} label={I18n.t('SAVE')} icon="pi pi-check" className="p-button-text" onClick={this.saveDescriptionElement}/>
            </React.Fragment>
        );
        const deleteElementDialogFooter = (
            <React.Fragment>
                <Button loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteElementDialog}/>
                <Button loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check" className="p-button-text" onClick={this.deleteElement}/>
            </React.Fragment>
        );

        const deleteElementDescriptionDialogFooter = (
            <React.Fragment>
                <Button loading={this.state.loadingDescriptionDialog} label={I18n.t('NO')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteElementDescriptionDialog}/>
                <Button loading={this.state.loadingDescriptionDialog} label={I18n.t('YES')} icon="pi pi-check" className="p-button-text" onClick={this.deleteElementDescription}/>
            </React.Fragment>
        );

        const deleteElementsDialogFooter = (
            <React.Fragment>
                <Button loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteElementsDialog}/>
                <Button loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedElements}/>
            </React.Fragment>
        );

        return (
            <div className="crud-demo">
                <Toast ref={(el) => this.toast = el}/>
                <ContextMenu model={this.state.menuModel} ref={el => this.cm = el} onHide={() => this.setState({selectedElement: null})}/>
                <div className="card">
                    <Toolbar className="p-mb-4" left={this.leftToolbarTemplate}></Toolbar>
                    <DataTable ref={(el) => this.dt = el} value={this.state.elements} selection={this.state.selectedElements} onSelectionChange={(e) => this.setState({selectedElements: e.value})}
                               dataKey="id" paginator lazy loading={this.state.loading} responsive
                               contextMenuSelection={this.state.selectedElement} onContextMenu={e => this.cm.show(e.originalEvent)}
                               onContextMenuSelectionChange={e => this.setState({selectedElement: e.value})}
                               rows={this.state.pageSize} totalRecords={this.state.totalRecords} first={this.state.first} onPage={this.onPage}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate={I18n.t('PAGE_REPORT_TEMPLATE')}
                               header={header}>
                        <Column selectionMode="multiple" headerStyle={{width: '3rem'}}></Column>
                        <Column field="nameFr" header={I18n.t('NAME')}></Column>
                        <Column field="category.nameFr" header={I18n.t('CATEGORY')}></Column>
                        <Column field="minImages" header={I18n.t('MIN_IMAGES')}></Column>
                        <Column field="maxImages" header={I18n.t('MAX_IMAGES')}></Column>
                        <Column header={I18n.t('STATUS_REQUIRED')} body={this.statusRequiredTemplate}></Column>
                        <Column header={I18n.t('PRICE_REQUIRED')} body={this.priceRequiredTemplate}></Column>
                    </DataTable>
                </div>

                <Dialog visible={this.state.elementDialog} style={{width: '950px'}} header={I18n.t('DETAILS')} modal className="p-fluid" footer={elementDialogFooter} closable={false} onHide={this.hideDialog}>
                    <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                        <InputText
                            required
                            autoFocus
                            name="namefr"
                            value={this.state.element.nameFr}
                            onChange={(e) => this.onInputChange(e, 'nameFr')}
                            id="namefr"
                            className={classNames({'p-invalid': this.state.submitted && !this.state.element.nameFr})}
                        />
                        {this.state.submitted && !this.state.element.nameFr && <small className="p-error">{I18n.t('NAME_FR_IS_REQUIRED')}</small>}
                        <label htmlFor="namefr">{I18n.t('NAME_FR')}</label>
                    </div>

                    <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                        <InputText
                            required
                            name="nameen"
                            value={this.state.element.nameEn}
                            onChange={(e) => this.onInputChange(e, 'nameEn')}
                            id="nameen"
                            className={classNames({'p-invalid': this.state.submitted && !this.state.element.nameEn})}
                        />
                        {this.state.submitted && !this.state.element.nameEn && <small className="p-error">{I18n.t('NAME_EN_IS_REQUIRED')}</small>}
                        <label htmlFor="nameen">{I18n.t('NAME_EN')}</label>
                    </div>

                    <div className="p-field-checkbox" style={{marginTop: '10px'}}>
                        <Checkbox
                            inputId="statusRequired"
                            value={this.state.element.statusRequired}
                            onChange={(e) => this.onInputChange(e, 'statusRequired')}
                            checked={this.state.element.statusRequired}>
                        </Checkbox>
                        <label htmlFor="statusRequired">{I18n.t('STATUS_REQUIRED')}</label>
                    </div>

                    <div className="p-field-checkbox" style={{marginTop: '10px'}}>
                        <Checkbox
                            inputId="priceRequired"
                            value={this.state.element.priceRequired}
                            onChange={(e) => this.onInputChange(e, 'priceRequired')}
                            checked={this.state.element.priceRequired}>
                        </Checkbox>
                        <label htmlFor="priceRequired">{I18n.t('PRICE_REQUIRED')}</label>
                    </div>

                    <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                        <Dropdown
                            required
                            name="categoryId"
                            value={this.state.element.categoryId}
                            onChange={(e) => this.onInputChange(e, 'categoryId')}
                            options={this.state.categories}
                            optionLabel="name"
                            optionValue="value"
                            id="categoryId"
                            className={classNames({'p-invalid': this.state.submitted && !this.state.element.categoryId})}
                        />
                        {this.state.submitted && !this.state.element.categoryId && <small className="p-error">{I18n.t('CATEGORY_IS_REQUIRED')}</small>}
                        <label htmlFor="categoryId">{I18n.t('CATEGORY')}</label>
                    </div>

                    <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                        <InputText
                            type="number"
                            required
                            name="minImages"
                            value={this.state.element.minImages}
                            onChange={(e) => this.onInputChange(e, 'minImages')}
                            id="minImages"
                            className={classNames({'p-invalid': this.state.submitted && !this.state.element.minImages})}
                        />
                        {this.state.submitted && !this.state.element.minImages && <small className="p-error">{I18n.t('MIN_IMAGES_IS_REQUIRED')}</small>}
                        <label htmlFor="minImages">{I18n.t('MIN_IMAGES')}</label>
                    </div>

                    <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                        <InputText
                            type="number"
                            required
                            name="maxImages"
                            value={this.state.element.maxImages}
                            onChange={(e) => this.onInputChange(e, 'maxImages')}
                            id="maxImages"
                            className={classNames({'p-invalid': this.state.submitted && !this.state.element.maxImages})}
                        />
                        {this.state.submitted && !this.state.element.maxImages && <small className="p-error">{I18n.t('MAX_IMAGES_IS_REQUIRED')}</small>}
                        <label htmlFor="maxImages">{I18n.t('MAX_IMAGES')}</label>
                    </div>

                    <div className="card">
                        <Toolbar className="p-mb-4" left={this.leftDescriptionToolbarTemplate}></Toolbar>
                        <DataTable reorderableColumns ref={(el) => this.dt = el} value={this.state.element.elements}
                                   dataKey="code" paginator rows={20}
                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items"
                                   onRowReorder={this.onRowReorder}>
                            <Column rowReorder style={{width: '3em'}}/>
                            <Column field="label" header={I18n.t('LABEL')}></Column>
                            <Column field="type" header={I18n.t('TYPE')}></Column>
                            <Column field="unit" header={I18n.t('UNIT')}></Column>
                            <Column field="icon" header={I18n.t('ICON')}></Column>
                            <Column body={this.actionBodyTemplate}></Column>
                        </DataTable>
                    </div>
                </Dialog>

                <Dialog visible={this.state.elementDescriptionDialog} style={{width: '450px'}} header={I18n.t('DETAILS')} modal className="p-fluid" footer={elementDialogDescriptionFooter} closable={false} onHide={this.hideDescriptionDialog}>
                    <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                        <InputText
                            type="label"
                            required
                            name="label"
                            value={this.state.elementDescription.label}
                            onChange={(e) => this.onInputDescriptionChange(e, 'label')}
                            id="label"
                            className={classNames({'p-invalid': this.state.submittedDescription && !this.state.elementDescription.label})}
                        />
                        {this.state.submittedDescription && !this.state.elementDescription.label && <small className="p-error">{I18n.t('LABEL_IS_REQUIRED')}</small>}
                        <label htmlFor="label">{I18n.t('LABEL')}</label>
                    </div>

                    <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                        <Dropdown
                            required
                            name="type"
                            value={this.state.elementDescription.type}
                            onChange={(e) => this.onInputDescriptionChange(e, 'type')}
                            options={this.descriptionTypes}
                            optionLabel="name"
                            optionValue="value"
                            id="type"
                            className={classNames({'p-invalid': this.state.submittedDescription && !this.state.elementDescription.type})}
                        />
                        {this.state.submittedDescription && !this.state.elementDescription.type && <small className="p-error">{I18n.t('TYPE_IS_REQUIRED')}</small>}
                        <label htmlFor="type">{I18n.t('TYPE')}</label>
                    </div>

                    <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                        <Chips inputId="values" value={this.state.elementDescription.values} onChange={(e) => this.onInputDescriptionChange(e, 'values')} separator=","/>
                        <label htmlFor="values">{I18n.t('VALUES')}</label>
                    </div>

                    <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                        <InputText
                            type="icon"
                            required
                            name="icon"
                            value={this.state.elementDescription.icon}
                            onChange={(e) => this.onInputDescriptionChange(e, 'icon')}
                            id="icon"
                        />
                        <label htmlFor="icon">{I18n.t('ICON')}</label>
                    </div>

                    <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                        <InputText
                            type="unit"
                            required
                            name="unit"
                            value={this.state.elementDescription.unit}
                            onChange={(e) => this.onInputDescriptionChange(e, 'unit')}
                            id="unit"
                        />
                        <label htmlFor="unit">{I18n.t('UNIT')}</label>
                    </div>

                    <div className="p-field-checkbox" style={{marginTop: '10px'}}>
                        <Checkbox
                            inputId="required"
                            value={this.state.elementDescription.required}
                            onChange={(e) => this.onInputDescriptionChange(e, 'required')}
                            checked={this.state.elementDescription.required}>
                        </Checkbox>
                        <label htmlFor="required">{I18n.t('FIELD_IS_REQUIRED')}</label>
                    </div>

                </Dialog>

                <Dialog visible={this.state.deleteElementDialog} style={{width: '450px'}} header={I18n.t('CONFIRMATION')} modal footer={deleteElementDialogFooter} closable={false} onHide={this.hideDeleteElementDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.element && <Translate dangerousHTML value="DELETE_ONE_WARNING" name={this.state.element.name}/>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteElementDescriptionDialog} style={{width: '450px'}} header={I18n.t('CONFIRMATION')} modal footer={deleteElementDescriptionDialogFooter} closable={false} onHide={this.hideDeleteElementDescriptionDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.elementDescription && <Translate dangerousHTML value="DELETE_ONE_WARNING" name={this.state.elementDescription.label}/>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteElementsDialog} style={{width: '450px'}} header={I18n.t('CONFIRMATION')} modal footer={deleteElementsDialogFooter} closable={false} onHide={this.hideDeleteElementsDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.selectedElements.length && <Translate dangerousHTML value="DELETE_MANY_WARNING" count={this.state.selectedElements.length}/>}
                    </div>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const locale = state.i18n.locale;
    return {
        locale
    };
}

export default connect(mapStateToProps)(SubCategory);


