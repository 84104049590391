import {UAParser} from "ua-parser-js";
import * as  arch from "arch";
import {v4 as uuidv4} from 'uuid';
import React from "react";

export const email = {
    value: '',
}

export const idData = {
    label: '',
    id: '',
}

export const price = {
    priceCurrencyEnum: '',
    etPrice: null,
}

export const phone = {
    dialCode: '',
    countryCode: '',
    name: '',
    formattedNumber: '',
    valid: true,
}

export const address = {
    streetNumber: '',
    streetAddress: '',
    addressLine2: '',
    city: '',
    postalOrZipCode: '',
    stateOrProvinceOrRegion: '',
    country: '',
    countryCode: '',
    label: '',
    value: {
        place_id: '',
    },
}

export const getFormattedPhone = (value, country) => {
    country['formattedNumber'] = value
    return country;
}

export const inArray = (array, values) => {
    for (const element of values) {
        if (array.indexOf(element) !== -1) {
            return true;
        }
    }
    return false;
}

export const mapTranslation = (translations) => {
    const res = [];
    Object.entries(translations).map(([code, array]) => {
        const item = {code: code, translation: array[0], kind: array[1]};
        res.push(item)
        return res
    })
    return res;
}

export const mapProfileTranslation = (translations) => {
    const res = [];
    Object.entries(translations).map(([code, value]) => {
        const item = {code: code, translation: value};
        res.push(item)
        return res
    })
    return res;
}

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const string_to_slug = (str, separator, addUUID) => {
    str = str.trim();
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    const to = "aaaaaaeeeeiiiioooouuuunc------";

    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    let result = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-") // collapse dashes
        .replace(/^-+/, "") // trim - from start of text
        .replace(/-+$/, "") // trim - from end of text
        .replace(/-/g, separator);

    if (addUUID) {
        result = result + '-' + uuidv4()
    }

    return result;
}

export const getBrowser = () => {
    let browser, cpu, device, engine, os, parser;
    parser = new UAParser();
    browser = parser.getBrowser();
    device = parser.getDevice();
    engine = parser.getEngine();
    os = parser.getOS();
    cpu = parser.getCPU();
    return {
        browserName: browser.name,
        browserVersion: browser.version,
        deviceModel: device.model,
        deviceType: device.type,
        deviceVendor: device.vendor,
        engineName: engine.name,
        engineVersion: engine.version,
        osName: os.name,
        osVersion: os.version,
        cpuArchitecture: !!cpu.architecture ? cpu.architecture : arch(),
        language: navigator.language,
        languages: navigator.languages,
        userAgent: navigator.userAgent,
        referrer: document.referrer || 'N/A',
        online: navigator.onLine,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        screenResolution: window.screen.width + ' x ' + window.screen.height,
        cookieEnabled: navigator.cookieEnabled
    };
}

export const toBackHashMap = (array) => {
    let obj = {};
    for (let i = 0; i < array.length; i++) {
        obj[array[i].key] = array[i].value;
    }
    return obj;
}

export const toFrontHasMap = (obj) => {
    const array = [];
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            const item = {key, value: obj[key]};
            array.push(item)
        }
    }
    return array;
}

export const isFormFieldInvalid = (props, name, attribute) => {
    if (!!attribute && Object.keys(props.touched).length !== 0 && Object.keys(props.errors).length !== 0) {
        return !!(props.touched[name] && props.errors[name] && props.errors[name][attribute]);
    }
    return !!(props.touched[name] && props.errors[name]);
}

export const getFormErrorMessage = (props, name, attribute) => {
    if (!!attribute && Object.keys(props.touched).length !== 0 && Object.keys(props.errors).length !== 0) {
        return isFormFieldInvalid(props, name, attribute) &&
            <small className="p-error">{props.errors[name][attribute]}</small>;
    }
    return isFormFieldInvalid(props, name) && <small className="p-error">{props.errors[name]}</small>;
}

export const extension = (filename) => {
    const r = /.+\.(.+)$/.exec(filename);
    return r ? r[1] : "";
}

export const isFormFieldInvalid2 = (touched, errors, field) => {
    const fields = field.name.split(".");
    if (fields && errors[fields[0]] && errors[fields[0]][fields[1]] && errors[fields[0]][fields[1]][fields[2]]) {
        return errors[fields[0]][fields[1]][fields[2]];
    }
    return false
}

export const getFormErrorMessage2 = (touched, errors, field) => {
    if (isFormFieldInvalid2(touched, errors, field)) {
        const fields = field.name.split(".");
        return <small className="p-error">{errors[fields[0]][fields[1]][fields[2]]}</small>
    }
    return "";
}

export const getProfileUrl = (account) => {
    if (account == null) {
        return "/assets/images/avatar_male.png";
    }
    let accountData = account.data

    if (accountData.photo) {
        return accountData.photo.url;
    } else {
        if (accountData.genderTypeEnum === "MALE") {
            return "/assets/images/avatar_male.png";
        } else {
            return "/assets/images/avatar_female.png";
        }
    }
}

