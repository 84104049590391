import React, {Component} from 'react';
import {classNames} from 'primereact/utils';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {FileUpload} from 'primereact/fileupload';
import {Toolbar} from 'primereact/toolbar';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import CategoryService from "../services/CategoryService";
import {I18n, Translate} from "react-redux-i18n";
import {ContextMenu} from "primereact/contextmenu";
import {Dropdown} from "primereact/dropdown";
import {Order} from "../helpers/order";
import {connect} from "react-redux";
import './Category.css';

class Category extends Component {
    emptyElement = {
        id: null,
        nameFr: '',
        nameEn: '',
        selectedFile: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            first: 0,
            pageSize: 5,
            totalRecords: 0,
            elements: [],
            elementDialog: false,
            deleteElementDialog: false,
            deleteElementsDialog: false,
            element: this.emptyElement,
            selectedElement: null,
            selectedElements: [],
            menuModel: [],
            sortFilterValues: [],
            submitted: false,
            loadingDialog: false,
            searchFilterValue: '',
            sortFilterValue: null
        };

        this.elementService = new CategoryService();
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.onPage = this.onPage.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveElement = this.saveElement.bind(this);
        this.editElement = this.editElement.bind(this);
        this.confirmDeleteElement = this.confirmDeleteElement.bind(this);
        this.deleteElement = this.deleteElement.bind(this);
        this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
        this.deleteSelectedElements = this.deleteSelectedElements.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onGlobalFilterChange = this.onGlobalFilterChange.bind(this);
        this.hideDeleteElementDialog = this.hideDeleteElementDialog.bind(this);
        this.hideDeleteElementsDialog = this.hideDeleteElementsDialog.bind(this);
        this.onFileSelect = this.onFileSelect.bind(this);
        this.onFileClear = this.onFileClear.bind(this);
        this.imageBodyTemplate = this.imageBodyTemplate.bind(this);
    }

    componentDidMount() {
        this.createOptions();
        this.setState({loading: true});
        this.elementService.getPage(0, this.state.pageSize, this.state.sortFilterValue?.field, this.state.sortFilterValue?.order).then(
            response => {
                this.setState({
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            }
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locale !== this.props.locale) {
            this.createOptions();
        }
    }

    createOptions() {
        const menuModel = [
            {label: I18n.t('EDIT_DATA'), icon: 'pi pi-fw pi-pencil', command: () => this.editElement(this.state.selectedElement)},
            {label: I18n.t('DELETE_DATA'), icon: 'pi pi-fw pi-trash', command: () => this.confirmDeleteElement(this.state.selectedElement)}
        ];

        const sortFilterValues = [
            {name: I18n.t('NAME_FR') + ' ' + I18n.t('ASCENDING'), field: 'nameFr', order: Order.ASC},
            {name: I18n.t('NAME_FR') + ' ' + I18n.t('DESCENDING'), field: 'nameFr', order: Order.DESC}
        ];
        this.setState(({
            menuModel,
            sortFilterValues
        }));
    }

    onPage(event) {
        this.setState({loading: true});
        const {page, first, rows} = event;
        let searchFilterValue =[{field: 'name', value: this.state.searchFilterValue}];
        this.elementService.getPage(page, rows, this.state.sortFilterValue?.field, this.state.sortFilterValue?.order, searchFilterValue).then(
            response => {
                this.setState({
                    first,
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            }
        );
    }

    openNew() {
        this.setState({
            element: this.emptyElement,
            submitted: false,
            elementDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            elementDialog: false,
            element: this.emptyElement
        });
    }

    hideDeleteElementDialog() {
        this.setState({deleteElementDialog: false});
    }

    hideDeleteElementsDialog() {
        this.setState({deleteElementsDialog: false});
    }

    saveElement() {
        let state = {submitted: true};

        if ((!!this.state.element.nameFr.trim() && !!this.state.element.nameEn.trim()) && (this.state.element.selectedFile != null || this.state.element.id != null)) {
            let elements = [...this.state.elements];
            this.setState({
                loadingDialog: true,
            });

            const data = new FormData();
            if (this.state.element.selectedFile) {
                data.append("file", this.state.element.selectedFile, this.state.element.selectedFile.name);
            }

            const jsonData = {
                nameFr: this.state.element.nameFr,
                nameEn: this.state.element.nameEn,
            };

            data.append("data", JSON.stringify(jsonData));
            if (this.state.element.id) { //may be update
                this.elementService.edit(this.state.element.id, data)
                    .then((response) => {
                        const index = this.findIndexById(this.state.element.id);
                        elements[index] = response.data.content;
                        state = {
                            ...state,
                            elements,
                            elementDialog: false,
                            element: this.emptyElement,
                            submitted: false,
                            loadingDialog: false
                        };
                        this.setState(state);
                        this.toast.show({severity: 'success', summary: I18n.t('UPDATE'), detail: I18n.t('UPDATE_SUCCESSFUL'), life: 6000});
                    })
                    .catch(error => {
                        this.setState({
                            loadingDialog: false,
                        });
                        this.toast.show({severity: 'error', summary: I18n.t('UPDATE'), detail: I18n.t(error.response.data.code), life: 6000});
                    });
            } else { //may be creation
                this.elementService.add(data)
                    .then((response) => {
                        elements.unshift(response.data.content);
                        state = {
                            ...state,
                            elements,
                            elementDialog: false,
                            element: this.emptyElement,
                            submitted: false,
                            loadingDialog: false,
                            totalRecords: this.state.totalRecords + 1,
                        };
                        this.setState(state);
                        this.toast.show({severity: 'success', summary: I18n.t('ADD'), detail: I18n.t('ADD_SUCCESSFUL'), life: 6000});
                    })
                    .catch(error => {
                        this.setState({
                            loadingDialog: false,
                        });
                        this.toast.show({severity: 'error', summary: I18n.t('ADD'), detail: I18n.t(error.response.data.code), life: 6000});
                    });
            }
        }
        this.setState(state);
    }

    editElement(element) {
        this.setState({
            element: {...element},
            elementDialog: true
        });
    }

    confirmDeleteElement(element) {
        this.setState({
            element,
            deleteElementDialog: true
        });
    }

    deleteElement() {
        this.setState({
            loadingDialog: true,
        });
        this.elementService.delete(this.state.element.id)
            .then(() => {
                let elements = this.state.elements.filter(val => val.id !== this.state.element.id);
                let selectedElements = this.state.selectedElements.filter(val => val.id !== this.state.element.id);
                this.setState({
                    elements,
                    deleteElementDialog: false,
                    element: this.emptyElement,
                    totalRecords: this.state.totalRecords - 1,
                    loadingDialog: false,
                    selectedElements
                });
                this.toast.show({severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    deleteElementDialog: false,
                    element: this.emptyElement,
                    loadingDialog: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('DELETION'), detail: I18n.t(error.response.data.code), life: 6000});
            });
    }

    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.elements.length; i++) {
            if (this.state.elements[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    confirmDeleteSelected() {
        this.setState({deleteElementsDialog: true});
    }

    deleteSelectedElements() {
        this.setState({
            loadingDialog: true,
        });
        let dataIds = this.state.selectedElements.map((el) => el.id);
        this.elementService.deleteMany(dataIds)
            .then(() => {
                let elements = this.state.elements.filter(val => !this.state.selectedElements.includes(val));
                this.setState({
                    totalRecords: this.state.totalRecords - this.state.selectedElements.length,
                    elements,
                    deleteElementsDialog: false,
                    selectedElements: [],
                    loadingDialog: false,
                });
                this.toast.show({severity: 'success', summary: I18n.t('DELETION'), detail: I18n.t('DELETION_SUCCESSFUL'), life: 6000});
            })
            .catch(error => {
                this.setState({
                    deleteElementsDialog: false,
                    loadingDialog: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('DELETION'), detail: I18n.t(error.response.data.code), life: 6000});
            });
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let element = {...this.state.element};
        element[`${name}`] = val;
        this.setState({element});
    }

    onGlobalFilterChange(e, filterName) {
        const value = (e.target && e.target.value) || '';
        let searchFilterValue = this.state.searchFilterValue;
        let sortFilterValue = this.state.sortFilterValue;
        if (filterName === 'searchFilter') {
            searchFilterValue = value;
            this.setState({searchFilterValue, loading: true, first: 0});
        }

        if (filterName === 'sortFilter') {
            sortFilterValue = e.value
            this.setState({sortFilterValue, loading: true, first: 0});
        }

        let searchValue =[{field: 'name', value: searchFilterValue}];
        this.elementService.getPage(this.state.first, this.state.pageSize, sortFilterValue?.field, sortFilterValue?.order, searchValue)
            .then(response => {
                this.setState({
                    elements: response.data.content.list ? response.data.content.list : [],
                    totalRecords: response.data.content.totalCount,
                    loading: false
                })
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
                this.toast.show({severity: 'error', summary: I18n.t('SEARCH'), detail: I18n.t('SEARCH_NOT_IMPLEMENTED'), life: 6000});
            });
    }

    onFileSelect(event) {
        let element = {...this.state.element};
        element["selectedFile"] = event.files[0];
        this.setState({element});
    }

    onFileClear() {
        let element = {...this.state.element};
        element["selectedFile"] = null;
        this.setState({element});
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label={I18n.t('ADD_DATA')} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew}/>
                <Button label={I18n.t('DELETE_DATA')} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedElements.length}/>
            </React.Fragment>
        )
    }

    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label={I18n.t('IMPORT_DATA')} chooseLabel="Import" className="p-mr-2 p-d-inline-block"/>
                <Button label={I18n.t('EXPORT_DATA')} icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV}/>
            </React.Fragment>
        )
    }

    imageBodyTemplate(rowData) {
        return <img src={rowData.image.url} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.image.name} className="product-image"/>
    }

    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-m-0">{I18n.t('DEPARTMENTS')}</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search"/>
                    <InputText style={{width: '300px'}} type="search" value={this.state.searchFilterValue} onInput={(e) => this.onGlobalFilterChange(e, 'searchFilter')} placeholder={I18n.t('SEARCH_DATA')}/>
                 <Dropdown style={{width: '200px', marginLeft: '7px'}} value={this.state.sortFilterValue} onChange={(e) => this.onGlobalFilterChange(e, 'sortFilter')}
                           options={this.state.sortFilterValues} optionLabel="name" placeholder={I18n.t('SELECT_ORDER')}/>
                </span>
            </div>
        );
        const elementDialogFooter = (
            <React.Fragment>
                <Button loading={this.state.loadingDialog} label={I18n.t('CANCEL')} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog}/>
                <Button loading={this.state.loadingDialog} label={I18n.t('SAVE')} icon="pi pi-check" className="p-button-text" onClick={this.saveElement}/>
            </React.Fragment>
        );
        const deleteElementDialogFooter = (
            <React.Fragment>
                <Button loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteElementDialog}/>
                <Button loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check" className="p-button-text" onClick={this.deleteElement}/>
            </React.Fragment>
        );
        const deleteElementsDialogFooter = (
            <React.Fragment>
                <Button loading={this.state.loadingDialog} label={I18n.t('NO')} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteElementsDialog}/>
                <Button loading={this.state.loadingDialog} label={I18n.t('YES')} icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedElements}/>
            </React.Fragment>
        );

        return (
            <div className="crud-demo">
                <Toast ref={(el) => this.toast = el}/>
                <ContextMenu model={this.state.menuModel} ref={el => this.cm = el} onHide={() => this.setState({selectedElement: null})}/>
                <div className="card">
                    <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>

                    <DataTable ref={(el) => this.dt = el} value={this.state.elements} selection={this.state.selectedElements} onSelectionChange={(e) => this.setState({selectedElements: e.value})}
                               dataKey="id" paginator lazy loading={this.state.loading} responsive
                               contextMenuSelection={this.state.selectedElement} onContextMenu={e => this.cm.show(e.originalEvent)}
                               onContextMenuSelectionChange={e => this.setState({selectedElement: e.value})}
                               rows={this.state.pageSize} totalRecords={this.state.totalRecords} first={this.state.first} onPage={this.onPage}
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                               currentPageReportTemplate={I18n.t('PAGE_REPORT_TEMPLATE')}
                               header={header}>

                        <Column selectionMode="multiple" headerStyle={{width: '3rem'}}></Column>
                        <Column field="nameFr" header={I18n.t('NAME_FR')}></Column>
                        <Column field="nameEn" header={I18n.t('NAME_EN')}></Column>
                        <Column header="Image" body={this.imageBodyTemplate}></Column>
                    </DataTable>
                </div>

                <Dialog visible={this.state.elementDialog} style={{width: '450px'}} header={I18n.t('DETAILS')} modal className="p-fluid" footer={elementDialogFooter} closable={false} onHide={this.hideDialog}>
                    <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                        <InputText
                            required
                            autoFocus
                            name="namefr"
                            value={this.state.element.nameFr}
                            onChange={(e) => this.onInputChange(e, 'nameFr')}
                            id="namefr"
                            className={classNames({'p-invalid': this.state.submitted && !this.state.element.nameFr})}
                        />
                        {this.state.submitted && !this.state.element.nameFr && <small className="p-error">{I18n.t('NAME_FR_IS_REQUIRED')}</small>}
                        <label htmlFor="namefr">{I18n.t('NAME_FR')}</label>
                    </div>

                    <div className="p-float-label p-field" style={{marginTop: '10px'}}>
                        <InputText
                            required
                            name="nameen"
                            value={this.state.element.nameEn}
                            onChange={(e) => this.onInputChange(e, 'nameEn')}
                            id="nameen"
                            className={classNames({'p-invalid': this.state.submitted && !this.state.element.nameEn})}
                        />
                        {this.state.submitted && !this.state.element.nameEn && <small className="p-error">{I18n.t('NAME_EN_IS_REQUIRED')}</small>}
                        <label htmlFor="nameen">{I18n.t('NAME_EN')}</label>
                    </div>

                    <div className="p-field">
                        <FileUpload chooseLabel={I18n.t('CHOOSE')} uploadLabel={I18n.t('UPLOAD')} cancelLabel={I18n.t('CANCEL')} name="file" url="./upload.php" customUpload onSelect={this.onFileSelect} onClear={this.onFileClear} onRemove={this.onFileClear} accept="image/*" maxFileSize={100000000}/>
                        {this.state.submitted && !this.state.element.selectedFile && !this.state.element.id && <small className="p-error">{I18n.t('IMAGE_IS_REQUIRED')}</small>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteElementDialog} style={{width: '450px'}} header={I18n.t('CONFIRMATION')} modal footer={deleteElementDialogFooter} closable={false} onHide={this.hideDeleteElementDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.element && <Translate dangerousHTML value="DELETE_ONE_WARNING" name={this.state.element.nameFr}/>}
                    </div>
                </Dialog>

                <Dialog visible={this.state.deleteElementsDialog} style={{width: '450px'}} header={I18n.t('CONFIRMATION')} modal footer={deleteElementsDialogFooter} closable={false} onHide={this.hideDeleteElementsDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.selectedElements.length && <Translate dangerousHTML value="DELETE_MANY_WARNING" count={this.state.selectedElements.length}/>}
                    </div>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const locale = state.i18n.locale;
    return {
        locale
    };
}

export default connect(mapStateToProps)(Category);


